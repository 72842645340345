<template>
  <div class="mx-5">
    <div class="container-graphic-select mx-2 mt-2 noprint">
      <slot name="selector"></slot>
      <div class="container-selector-graphic">
        <span class="mr-1">Tipo de gráfico:</span>
        <b-form-select
          id="chartType"
          v-model="selected_type"
          :options="chart_options"
          @change="updateChartType"
          class="select-graphic-type"
        ></b-form-select>
      </div>
    </div>
    <div class="d-flex">
      <!-- Gráfico -->
      <div class="chart-container">
        <canvas
          ref="chart"
          style="position: relative; height: 49vh; width: 100vw"
        ></canvas>
      </div>
      <!-- Tabla para gráficos circulares -->
      <div v-if="showTable" class="table-container mt-3">
        <b-table-simple bordered small style="width: auto">
          <b-thead>
            <b-tr>
              <b-th>{{ title_table_circular }}</b-th>
              <b-th v-for="range in legend_labels" :key="range.label">
                {{ range.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(label, index) in labels" :key="index">
              <b-td>{{ label }}</b-td>
              <b-td
                v-for="(dataset, datasetIndex) in datasets"
                :key="datasetIndex"
                :style="{ backgroundColor: legend_labels[datasetIndex].color }"
              >
                {{ dataset.data[index] }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <!-- Tabla al modo imprimir -->
    <div v-if="showTable" class="table-container-print mt-3">
      <b-table-simple bordered small>
        <b-thead>
          <b-tr>
            <b-th>{{ title_table_circular }}</b-th>
            <b-th v-for="range in legend_labels" :key="range.label">
              {{ range.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(label, index) in labels" :key="index">
            <b-td>{{ label }}</b-td>
            <b-td
              v-for="(dataset, datasetIndex) in datasets"
              :key="datasetIndex"
              :style="{
                backgroundColor: `${legend_labels[datasetIndex].color} !important`,
              }"
            >
              {{ dataset.data[index] }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "GenericGraphic",
  props: {
    type: {
      type: String,
      default: "bar",
      validator: (value) => ["bar", "line", "pie", "doughnut"].includes(value),
    },
    labels: {
      type: Array,
      required: true, // Etiquetas del eje X
    },
    datasets: {
      type: Array,
      required: true, // Datos del Eje Y
    },
    legend_labels: {
      type: Array,
      required: true, // Etiquetas de la leyenda
    },
    title_graphic: {
      type: String,
      required: true, // Titulo del gráfico
    },
    total_students: {
      type: Number,
      required: true, // Maximo del eje Y
    },
    label_y_axis: {
      type: String,
      required: false, // Label que aparece en el eje Y
      default: "",
    },
    step_size: {
      type: Number,
      required: false, // Numero de pasos que irá el numero en el eje y
    },
    title_table_circular: {
      type: String,
      required: false, // Texto del th para la tabla de los graficos circulares
      default: "",
    },
    integer_y_axis: {
      type: Boolean,
      required: false,
      default: true, // Define si los numeros del eje y serán enteros (true) o decimales (false)
    },
  },
  data() {
    return {
      selected_type: this.type, // Tipo de gráfico seleccionado
      chart_options: [
        { value: "bar", text: "Barras" },
        { value: "line", text: "Líneas" },
        { value: "pie", text: "Circular" },
        { value: "doughnut", text: "Dona" },
      ],
      chartInstance: null, // Instancia de Chart.js
    };
  },
  computed: {
    showTable() {
      return ["pie", "doughnut"].includes(this.selected_type);
    },
    // Caracteristicas del gráfico
    chartOptions() {
      const isCircular = ["pie", "doughnut"].includes(this.selected_type);
      const isLinear = ["bar", "line"].includes(this.selected_type);

      const baseOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          onClick: () => {},
          labels: {
            generateLabels: () => {
              return this.legend_labels.map((x) => ({
                text: x.label,
                fillStyle: x.color,
              }));
            },
          },
        },
        title: {
          display: true,
          text: this.title_graphic,
          fontSize: 15,
          fontColor: "#333",
          fontStyle: "bold",
        },
      };

      const circularOptions = {
        plugins: {
          datalabels: {
            color: "#3a3a3a",
            font: {
              size: 12,
            },
            formatter: (value) => (value == 0 ? "" : value),
          },
        },
        scales: {
          xAxes: [], // Elimina el eje X
          yAxes: [], // Elimina el eje Y
        },
      };

      const linearBarOptions = {
        plugins: {
          datalabels: {
            color: "#3a3a3a",
            font: {
              size: 12,
            },
            formatter: (value) => value,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: this.step_size ? this.step_size : "",
                max: this.total_students || 5,
                callback: (value) => {
                  if (this.integer_y_axis) {
                    return Number.isInteger(value) ? value : null;
                  }
                  return value;
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.label_y_axis,
              },
            },
          ],
        },
      };

      return {
        ...baseOptions,
        ...(isCircular ? circularOptions : {}),
        ...(isLinear ? linearBarOptions : {}),
      };
    },
  },
  methods: {
    // Actualizar el tipo de gráfico
    updateChartType() {
      this.renderChart();
    },
    // Renderizar el gráfico
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy(); // Destruir la instancia anterior
      }

      const ctx = this.$refs.chart.getContext("2d");
      const chartType = this.selected_type;

      // Ajustar datasets para gráficos de línea
      const datasets = this.datasets.map((dataset) => {
        if (chartType == "line") {
          return {
            ...dataset,
            fill: false,
            borderColor: dataset.borderColor || "#000",
            borderWidth: dataset.borderWidth || 0.5,
            pointRadius: 5,
            pointHoverRadius: 7,
          };
        }
        return dataset;
      });

      // Crear la instancia de Chart.js
      this.chartInstance = new Chart(ctx, {
        plugins: chartType == "line" ? [] : [ChartDataLabels], // Desactivar datalabels para el gráfico
        type: chartType,
        data: {
          labels: this.labels,
          datasets,
        },
        options: this.chartOptions,
      });
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    // Observar cambios en labels, datasets y options
    labels: "renderChart",
    datasets: "renderChart",
    options: "renderChart",
  },
  beforeDestroy() {
    // Destruir la instancia de Chart.js antes de desmontar el componente
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
.select-graphic-type {
  max-width: 200px;
}
.container-selector-graphic {
  display: flex;
  min-width: 400px;
  justify-content: right;
  align-items: center;
}
.container-graphic-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
th {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.chart-container {
  flex: 1;
}
.table-container {
  margin-left: auto;
  font-size: 12px;
  max-height: 45vh !important;
  overflow-y: auto;
}
.table-container-print {
  display: none;
}
@media print {
  .table-container {
    display: none !important;
  }
  .table-container-print {
    display: block !important;
  }
}
</style>
